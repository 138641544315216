import React from "react"
import { Link } from "gatsby"
import Info from "../components/Info"
import { Row, Col, Layout, Button } from "antd"
import OrangeNavbarLogo from "../images/OrangeNavbarLogo.svg"
import Error500 from "../images/Error500.svg"
import SEO from "../components/seo"
import { handlers } from '../helpers/handlers'

const { Content, Header } = Layout

const InternalServerError = () => {
  const { isMobile } = handlers.useDeviceDetect();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  
  return (
    <Layout>
      <SEO
        title="Internal server error | CMT"
        dataLayer={{
          userId: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
      />
      <Header className="header" theme="light">
        <Row justify="center">
          <Col lg={22} xs={24}>
            <div className="logo" style={{ float: "left", marginLeft: 5 }}>
              <Link to="/" replace>
                <img
                  src={OrangeNavbarLogo}
                  alt="CMT logo naranja"
                  height={30}
                />
              </Link>
            </div>
            <Info
              device='device-desktop'
              isMobile={isMobile}
              splitPhone={handlers.splitPhone}
              countryCode={countryCode}
            />
          </Col>
        </Row>
      </Header>
      <Content className="mt-20">
        <Row justify="center" className="internal-server-error-container">
          <Col lg={24} xs={24}>
            <Row justify="center">
              <Col lg={20} xs={24} className="internal-server-error-content">
                <Row justify="center" align="middle" className="pt-100">
                  <Col lg={20} xs={24}>
                    <Row justify="center">
                      <Col lg={3} className="mb-20 text-center">
                        <img src={Error500} alt="error 500 icon" />
                      </Col>
                      <Col lg={22} xs={24}>
                        <h1 style={{ textAlign: "center" }}>Error 500</h1>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col lg={20} xs={24} className="mt-20">
                        <h2 style={{ textAlign: "center" }}>
                          Lo sentimos, ha ocurrido un error.
                        </h2>
                      </Col>
                      <Col lg={18} xs={24}>
                        <p style={{ textAlign: "center" }}>
                          Ya estamos trabajando para solucionarlo.
                        </p>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col lg={5} xs={24} className="mt-60 mb-100 text-center">
                        <Link to="/" replace>
                          <Button size="large" className="button">
                            Ir a la página de inicio
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default InternalServerError
